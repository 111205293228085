.cancel-button {
  cursor: pointer;
  width: fit-content;
  z-index: 1000;
  background-color: #fff;
  padding: 7px;
  border-radius: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}
